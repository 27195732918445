// logo 

.client-logo {
    .client-list {
        img {
            opacity: 0.5;
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }
}