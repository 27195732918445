@import "variables";
@import "general";
@import "helper";
@import "components";
@import "avatar";
@import "menu";
@import "home";
@import "cta";
@import "client-logo";
@import "services";
@import "features";
@import "pricing";
@import "testimonials";
@import "about";
@import "switcher";
@import "team";
@import "contact";
@import "authentication";
@import "footer"